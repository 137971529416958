import React from 'react'
import PropTypes from 'prop-types'

const StaticNumbers = ({ staticNumbers }) => (

  
  <div className="mx-auto my-5">
    <div className="d-flex flex-wrap justify-content-between align-items-center">
      {staticNumbers.map((number) => (
        <div
          key={`staticNumbersId-${number.id}`}
          className="d-flex flex-column align-items-center p-3 m-auto primary-color"
        >
          <h1 className="display-2 font-weight-extra-bold">
            {number.quantity}
          </h1>
          <h6 className="font-weight-extra-bold">{number.description}</h6>
        </div>
      ))}
    </div>
  </div>
)

export default StaticNumbers

StaticNumbers.propTypes = {
  staticNumbers: PropTypes.array.isRequired
}
