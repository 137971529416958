import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import React from 'react'
import moment from 'moment'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Carousel from '../components/carousel'
import CareerList from '../components/main-render/careersList'
import Schedule from '../components/schedule'
import StaticNumber from '../components/main-render/staticNumbers'
import NoticesAndNovelties from '../components/noticesAndNovelties'
import NotesPreview from '../components/notesPreview'
import { Location } from '../components/home/location'
import DynamicZone from '../helpers/dynamicZone'

const MainRender = ({ data }) => {
  const {
    allStrapiDiary: diaries,
    allStrapiUccCareer: careers,
    strapiOrganizationalUnit: organizationalUnit
  } = data
  let SEO = ''

  if (organizationalUnit.pageMetadata !== null) {
    const { description, keywords, pageTitle } = organizationalUnit.pageMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  }

  // Filter and Sort news by date, from the query it is not possible
  const novelties = organizationalUnit.novelties
    .filter(
      (novelty) =>
        moment(novelty.dateTo).isSameOrAfter(moment()) ||
        novelty.dateTo === null
    )
    .sort(function (a, b) {
      return moment(b.date) - moment(a.date)
    })
    
  // Sort notes by date
  const notes =
    organizationalUnit.notes && organizationalUnit.notes.length > 0
      ? organizationalUnit.notes.sort((a, b) => moment(b.date).diff(moment(a.date)))
      : null
  
  return (
    <Layout
      page={organizationalUnit}
      logo={organizationalUnit.logo}
      scriptSelect={organizationalUnit?.scripts}
    >
      {SEO}
      <section>
        {organizationalUnit.header && (
          <div
            className={`d-flex flex-column header-carousel-uo ${
              organizationalUnit.navbar.length === 0 && 'no-navbar'
            }`}
          >
            <Carousel
              carousel={organizationalUnit.header}
              pages={data.allSitePage.nodes}
            />
          </div>
        )}
        <div className="pt-5">
          <h1
            className={`container ${
              !organizationalUnit.color && 'title-secondary'
            } text-uppercase ucc-text-blue font-weight-extra-bold`}
            style={{ color: organizationalUnit.color }}
          >
            {organizationalUnit.name}
          </h1>
          {organizationalUnit.ucc_careers && organizationalUnit.careerList && (
            <CareerList allCareers={careers.group} color={organizationalUnit.color} />
          )}
          <DynamicZone components={organizationalUnit.body} />
        </div>

        {organizationalUnit.novelties && organizationalUnit.noveltyList && (
          <div className="container">
            <NoticesAndNovelties novelties={novelties} />
          </div>
        )}
        {organizationalUnit.notes && organizationalUnit.notes !== null && (
          <div className="container">
            <NotesPreview notes={notes} view={3} />
          </div>
        )}
        {diaries.nodes.length > 0 && organizationalUnit.diaryList && (
          <div className="w-100 bg-dark">
            <Schedule diaries={diaries.nodes} />
          </div>
        )}
        <div className="container">
          {organizationalUnit.staticNumber &&
            organizationalUnit.staticNumber.visible && (
              <StaticNumber
                staticNumbers={organizationalUnit.staticNumber.number}
              />
            )}
        </div>
        <div className="container">
          {organizationalUnit.buildingLocation?.buildingsLocationList &&
            organizationalUnit.buildingLocation?.buildingsLocationList
              .visible && (
              <>
                <Location
                  data={
                    organizationalUnit?.buildingLocation.buildingsLocationList
                  }
                />
              </>
            )}
        </div>
      </section>
    </Layout>
  )
}

export default MainRender

MainRender.propTypes = {
  data: PropTypes.object.isRequired
}

export const mainRenderQuery = graphql`
  query($strapiId: Int) {
    strapiOrganizationalUnit(strapiId: { eq: $strapiId }) {
      pageMetadata {
        description: pageDescription
        keywords: pageKeywords
        pageTitle
      }
      name
      slug
      color
      careerList
      diaryList
      noveltyList
      logo {
        visible
        url
        title
        imageDark {
          url
          name
        }
        imageLight {
          url
          name
        }
      }
      navbar {
        name
        icon {
          code
          type
        }
        careersList {
          name
          ucc_careers {
            slug
            name
            id
            modality
          }
        }
        organizational_unit {
          name
          slug
        }
        organizationalUnit {
          name
          icon {
            code
            type
          }
          organizational_unit {
            name
            slug
            id
          }
        }
        page {
          name
          slug
        }
        pages {
          name
          icon {
            code
            type
          }
          page {
            id
            name
            slug
          }
        }
        ExternalLink {
          name
          url
          id
          icon {
            code
            type
          }
        }
        url
      }
      header {
        visible
        slide {
          content
          name
          url
          id
          visible
          imageFilter
          imageDescription
          textButton
          backgroundImage {
            url
            name
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
      scripts {
        source
        id
        enable
        name
      }
      body {
        id
        strapi_component
        title
        visible
        rightText
        leftText
        formTitle
        text
        carouselCard {
          color
          description
          labelBlack
        }
        careerFeedContent {
          id
          title
          modality
          ucc_career_place {
            title
          }
          career_level {
            name
          }
        }
        icons {
          alt
          content
          icon {
            code
            type
            name
          }
        }
        accordion {
          title
          summary
          id
        }
        iconTexts {
          id
          alt
          content
          icon {
            code
            type
          }
        }
        textContent {
          id
          content
          title
          variant
          backgroundImage {
            url
          }
          visible
        }
        videoBack {
          id
          description
          videoUrl
          buttonVideo {
            url
            content
            page {
              slug
            }
          }
          backgroundImage {
            url
          }
          video {
            url
          }
        }
        tabs {
          id
          title
          content
          itemsAccordion {
            id
            title
            content
          }
        }
        singleImage {
          id
          title
          visible
          image {
            url
          }
        }
        video {
          id
          visible
          title
          description
          videoId
          mediaVideo {
            url
          }
        }
        map {
          id
          code
          title
          visible
        }
        MapContent {
          id
          code
          title
          visible
          mapContentText {
            id
            address
            contact
            openingHours
            image {
              url
            }
          }
        }
        pdf {
          id
          code
          title
          visible
        }
        transitionContent {
          text
          imagePosition
          image {
            url
          }
          color
        }
        heroContent {
          id
          title
          summary
          image {
            url
          }
          imageDark {
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              label
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
        }
        BannerListContent {
          id
          title
          Card {
            id
            title
            description
            url
            icon {
              url
            }
            page {
              name
              slug
            }
            organizational_unit {
              name
              slug
            }
          }
        }
        quoteContent {
          title
          description
          id
          variant
          videoUrl
          image {
            name
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              label
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
          professional {
            name
            profilePicture {
              url
            }
          }
        }
        diagonalContent {
          id
          title
          variant
          image {
            url
          }
          imageDark {
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              label
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
        }
        logoSection {
          title
          summary
          media {
            name
            img {
              url
            }
            imageDark {
              url
            }
          }
        }
        cards {
          cardName
          content
          destacado
          id
          type
          url
          visible
          color
          labelBlack
          backgroundImageName
          backgroundImage {
            url
            name
          }
          page {
            slug
          }
          career_level {
            name
          }
          organizational_unit {
            slug
          }
        }
        slide {
          id
          name
          content
          imageDescription
          textButton
          url
          visible
          imageFilter
          backgroundImage {
            name
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
        socialNetworks {
          title
          visible
          facebook {
            code
            visible
          }
          instagram {
            code
            visible
          }
          twitter {
            code
            visible
          }
        }
        form {
          id
        }
        interest_receiver {
          option
          receiver
        }
        interest_type {
          option
          type
        }
        icon {
          code
          type
        }
        button {
          career_level {
            name
          }
          diary {
            slug
          }
          label
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
          url
        }
        customButton {
          shape
          size
          variant
        }
        profesionals {
          id
          rol
          visible
          professional {
            id
            name
            description
            profilePictureName
            profilePicture {
              url
              name
            }
          }
        }
      }
      buildingLocation {
        buildingsLocationList {
          title
          visible
          buildingsLocation {
            buildingName
            url
            information
          }
        }
      }
      ucc_careers {
        name
        slug
        id
        modality
      }
      novelties {
        id
        slug
        published_at
        summary
        title
        thumbnailName
        destacadoHome
        date
        dateTo
        thumbnail {
          url
        }
      }
      notes {
        id
        date
      }
      staticNumber {
        id
        visible
        number {
          id
          quantity
          description
        }
      }
    }
    allStrapiDiary(
      sort: { fields: date }
      filter: { organizational_unit: { id: { eq: $strapiId } } }
    ) {
      nodes {
        id
        title
        slug
        date(formatString: "YYYY/MM/DD", locale: "es")
        time
        place
        summary
        content
        diary_category {
          id
          name
        }
      }
    }
    allStrapiUccCareer(
      filter: { organizational_unit: { id: { eq: $strapiId } } }
    ) {
      group(field: career_category___career_level) {
        fieldValue
        nodes {
          id
          name
          slug
          modality
          career_category {
            id
            name
            career_level
          }
          ucc_career_places {
            id
            title
            colorHex
          }
        }
      }
    }
    allSitePage(
      filter: {
        componentChunkName: {
          in: [
            "component---src-templates-main-render-ou-js"
            "component---src-templates-internal-page-js"
          ]
        }
      }
    ) {
      nodes {
        path
      }
    }
  }
`
