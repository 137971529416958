import React from 'react'
import PropTypes, { object } from 'prop-types'
import { graphql, Link, useStaticQuery } from 'gatsby'
import './font-awesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'

import logo from '../images/newspaper.png'

const NotesPreview = ({ notes, isHomeUcc = false, view = null }) => {

  const data = useStaticQuery(graphql`
    query {
      allStrapiNotes(sort: {order: DESC, fields: date}) {
        nodes {
          strapiId
          title
          slug
          date
          summary
          novelties_categories {
            name
          }
          thumbnail {
            url
          }
        }
      }
    }
  `)
  
  const { allStrapiNotes: allNotes } = data

  // Seteamos en caso de que los datos vengan de la home
  const notesUcc = [] 
  {isHomeUcc === true && (
    notes.map((note) => {
      notesUcc.push(note.note)
    }),
    
    notes = notesUcc
  )}

  // Construyendo el objeto Notas (debido a que no puedo traer el Novelties categories)
  let notesObj = notes?.map((note) => {
    for(const noteId in allNotes.nodes) {
      const noteAll = allNotes.nodes[noteId]
      if (note?.id === noteAll.strapiId) {
        return noteConstructor(noteAll)
      }
    }})

  return notesObj ? (
  
    <div className="mb-5 container">
      {/* Title and button */}
      <div className="d-flex align-items-center justify-content-between mb-3 novelties-title">
        <h2 className={`title-secondary text-uppercase font-weight-extra-bold mb-0 ucc-text-blue`}>
          <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
          La católica en los{' '} <span className="font-weight-bold text-main pb-1">Medios</span>
        </h2>
        
        <Link
          to={'https://www.ucc.edu.ar/notas'}
          className={`btn btn-sm btn-outline-dark cards-section-button`}
          target="_blank"
        >
          Ver todas
        </Link>
      </div>

      {/* Preview de las Notas */}
      <div className="row">
        {notesObj.map((note, i) => {
          if (view === null || view > i) {
            return (
              <div
                className={`mb-4 col-12 col-md-4`}
                key={`news-all-NodesId-${note.strapiId}`}
              >
                {/* Imagen de la Nota */}
                <div className="h-100 d-flex flex-column rounded shadow-sm news-card overflow-hidden position-relative">
                  <div
                    className="news-image"
                    style={{
                      backgroundImage: `url(${note.thumbnail ? note.thumbnail.url : logo} )`
                    }}
                  ></div>

                  <div className="flex-grow-1 p-4 d-flex flex-column news-info">
                    <h6>
                      <small>
                        {moment(note.date)
                          .locale('es')
                          .format('DD-MM-YYYY')}
                      </small>
                    </h6>

                    {/* Etiqueta de la Nota - Utiliza la coleccion Novelties Categories */}
                    {note.novelties_categories.length > 0 ?
                      <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">  
                        {note.novelties_categories.map((noteCategory, i) => {
                          if ((note.novelties_categories.length - 1) > i) {
                            return noteCategory.name + " - "
                          } else {
                            return noteCategory.name
                          }
                        })}
                      </div>
                    : ' '}
                    
                    <h5 className="font-weight-bold">{note.title}</h5>

                    <div className='noticies-markdown markdown-format'>
                      <p>{note.summary}</p>
                    </div>

                    <Link
                      to={`https://www.ucc.edu.ar/notas/${note.slug}`}
                      className={'btn btn-outline-info mt-auto fit-content text-uppercase rounded-pill px-4 font-secondary eventuality-button-font-size'}
                      target="_blank"
                    >
                      LEER NOTA
                    </Link>
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  ) : null
}

// Construyendo estructura de las Notas
const noteConstructor = (note) => {
  return ({
    strapiId: note.strapiId,
    title: note.title,
    slug: note.slug,
    summary: note.summary, 
    thumbnail: { url: note.thumbnail?.url },
    date: note.date,
    novelties_categories: note.novelties_categories
  })
}

export default NotesPreview

NotesPreview.propTypes = {
  notes: PropTypes.array.isRequired,
}