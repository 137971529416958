import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Tabs, Tab } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { PlaceOutlined } from '@mui/icons-material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import '../../css/_careerList.scss'
import orderCareerByCategory from '../../helpers/orderCareerByCategory';


const CareerList = ({ allCareers, color }) => {
  
  const data = useStaticQuery(graphql`
    query {
      allStrapiCareerLevel {
        nodes {
          name
          strapiId
        }
      }
    }
  `)

  const { allStrapiCareerLevel: careerLevel } = data
  var espCsSalud = false

  if (!color) {
    color = '#182C49'
  }

  const hexToRgba = (hexColor) => {
    hexColor = hexColor.replace('#', '')
    const r = parseInt(hexColor.substring(0, 2), 16)
    const g = parseInt(hexColor.substring(2, 4), 16)
    const b = parseInt(hexColor.substring(4, 6), 16)
    return `${r}, ${g}, ${b}`
  }

  const rgbaColor = hexToRgba(color)

  const identifyLevelName = (career) => {
    if (careerLevel && careerLevel !== null) {
      const name = careerLevel.nodes.map((level) => {  
        if (level.strapiId === career){
          return level.name
        }
      })
      return name
    }
  }

  const getTooltipText = (career) => {
    if (career.modality === 'Distancia') {
      return 'A distancia'
    } else if (career.modality === 'Ambas') {
      return 'Presencial y a distancia'
    } else {
      return 'Presencial'
    }
  }

  const generateCareerLink = (career) => {
    return (
      <Link key={`careerNodesId-${career.id}`} className="items-link" to={`/carreras/${career.slug}`}>
        <div className='text-content'>
          <div className='textLink'>
            {career.name.toUpperCase()}
          </div>

          {career.modality == 'Distancia' ? (
            <div className="iconContainer"  role="tooltip" aria-label={getTooltipText(career)} >
              A Distancia
            </div>
          ) : career.modality == 'Ambas' ? (
            <div className="iconContainer"  role="tooltip" aria-label={getTooltipText(career)}>
              Presencial / A Distancia
            </div>
          ) : career.modality == 'Presencial' ? (
            <div className="iconContainer"  role="tooltip" aria-label={getTooltipText(career)}>
              Presencial
            </div>
           ) : null}
        </div>

        <div className='d-flex align-items-center'>
          {career.ucc_career_places ? 
            <div className='d-flex align-items-center' style={{minWidth: "141px"}}>
              <PlaceOutlined fontSize="large" className='mr-2'/>
              <div className='d-flex flex-column'>
                {career.ucc_career_places
                  ?.sort((a, b) => a.title.localeCompare(b.title)) // Alphabetical Order
                  .map((place) => {
                    return <span className='textLink'>{place.title}</span>
                })}
              </div>
            </div>
          :
            <ArrowForwardIosRoundedIcon fontSize="large" className='mr-2'/>
          }
        </div>
      </Link>
    )
  };

  return allCareers.length > 0 ? (
    <div 
      style={{'--background-color-active' : rgbaColor}} 
      className="mb-5 container font-secondary font-weight-medium tabs-componente"
    >
      <Tabs 
        fill 
        justify
        defaultActiveKey={`eventKey-${allCareers[0].fieldValue}`}
        id="uncontrolled-tab-example"
        className="nav-fill"
      >
        {allCareers?.map((careers, i) => {
          // Sort careers by category
          orderCareerByCategory(careers.nodes)
          
          return (
            <Tab
              key={`tabKeyId-${i}`}
              eventKey={`eventKey-${careers.fieldValue}`}
              title={identifyLevelName(
                allCareers[i].nodes[0].career_category?.career_level
              )}
            >
              <div
                className="pt-4 font-weight-normal items-container"
                key={`tabCareersId-${i}`}
              >
                {careers.nodes?.map((career) => {
                  // exclude health specialties careers from career list
                  if (career.career_category?.name === 'Especialidad Cs Salud' && espCsSalud === false) {
                    espCsSalud = true

                    const carrerCsSalud = {
                      name: "Especialidades Médicas",
                      slug: "../propuesta-academica/especialidades-cs-salud"
                    }
                  
                    return generateCareerLink(carrerCsSalud);
                  } else if (career.career_category?.name !== 'Especialidad Cs Salud') {
                    return generateCareerLink(career);
                  }
                })}
              </div>
            </Tab>
          )
        })}
      </Tabs>
    </div>
  ) : null
}

export default CareerList

CareerList.propTypes = {
  careers: PropTypes.array.isRequired,
  color: PropTypes.string
}
